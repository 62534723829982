@import '../../../../shared/styles/mixins.scss';
@import '../../../../shared/styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  border-radius: $border-radius5;
  background-color: $color-black80;
  padding: 25px 30px;
  @media (max-width: 600px) {
    padding: 20px;
  }
  .closeIcon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    top: 18px;
    right: 20px;
    svg {
      height: 15px;
      width: 15px;
      margin: 10px 5px 0px 0px;
      path {
        color: $color-secondary;
      }
    }
    @media (max-width: 600px) {
      top: 14px;
      right: 10px;
    }
  }
  .stepWrapper {
    display: flex;
    max-width: 100%;
    justify-content: center;
    flex-direction: column;
    // margin-bottom: 25px;
  }
  .actionBtns {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    > button {
      margin-top: 25px;
    }
  }
}
.stepContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--color-aux21);
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 300px;
    p {
      font-size: 32px;
      margin-top: 25px;
      font-weight: 700;
      letter-spacing: -0.02rem;
      line-height: 42px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .subTitle {
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    &_success {
      @extend .subTitle;
      font-weight: 400;
    }
  }
  .notValid {
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: $color-secondary;
    color: var(--color-red);
    margin-top: 10px;
  }
  .notCode {
    display: flex;
    text-align: center;
    flex-direction: row;
    .button,
    span {
      font-weight: 600;
      color: $color-secondary;
      font-size: 11px;
    }
    .isDisabled {
      color: var(--color-aux11);
      margin-left: 5px;
    }
    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .input {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
  .codeVerification {
    div {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    input {
      border: 1px solid var(--color-aux37) !important;
      background-color: var(--color-aux151);
      margin: 0px 5px;
      max-width: 38px;
      padding-top: 2px;
      max-height: 47px;
      border-radius: 8px !important;
      color: var(--color-aux21);
      font-weight: 700 !important;
      font-family: $font-family !important;
      font-size: 13px !important;
      caret-color: var(--color-aux21) !important;
    }
  }
  svg {
    margin-bottom: 30px;
  }
  [class$='PhoneInput'] {
    display: flex;
    padding: 20px;
    background-color: var(--color-aux151);
    border: 1px solid var(--color-aux37);
    border-radius: 8px;
    width: 100%;
    height: 3rem;
  }
  [class$='PhoneInputInput'] {
    background-color: transparent !important;
    border: none;
    color: var(--color-aux150);
    margin-left: 10px;
    height: 3rem;
    font-weight: 700;
    font-size: 13px;
    outline: none;
  }
  [class$='PhoneInput--focus'] {
    display: flex;
    padding: 20px;
    background-color: var(--color-aux151);
    border: 1px solid var(--color-aux37);
    border-radius: 8px;
    width: 100%;
    height: 3rem;
    max-height: 100px;
    outline: none;
  }
}
:global(.dark) {
  .title {
    color: #f2f2f2;
  }
  .subTitle {
    color: #808080;
  }
  .PhoneInputInput {
    background-color: red;
  }
}
:global(.light) {
  .title {
    color: #18243a;
  }
  .subTitle {
    color: #828282;
  }
}
