@import '../../shared/styles/mixins.scss';
@import '../../shared/styles/variables.scss';

.notification_indicator {
  border-radius: 99999px;
  background-color: $color-red;
  color: var(--color-aux3);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  min-height: 18px;
  font-weight: 700;
  box-sizing: border-box;
  border: 2px solid $color-black80;
  padding: 0 3px 0 2px;

  p {
    font-size: 9px;
    white-space: nowrap;
    text-align: center;
  }
}