@import '../../../../shared/styles/variables.scss';
@import '../../../../shared/styles/mixins.scss';

.navBarIcon {
  @include centerFlexContent;
  color: var(--color-aux15);
  transition: 0.2s;
  cursor: pointer;
  flex-direction: column;

  @include respond(tab-port) {
    margin-top: 2px;
    border-radius: 30px;
    height: 3rem;
    padding: 0 12px;
    margin-right: 4px;
  }

  @include respond(tab-land) {
  }

  &.active,
  &:hover {
    color: $color-secondary;

    svg {
      path {
        stroke: $color-secondary !important;
      }
    }

    .icon {
      align-items: flex-start;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    overflow-y: hidden;
    display: flex;
    align-items: flex-end;

    @include respond(tab-land) {
      width: 1.5rem;
      height: 1.5rem;
    }

    svg {
      height: auto;
      width: auto;

      path {
        stroke: var(--color-aux15);
      }
    }
  }

  &__name {
    font-weight: 600;
    margin: 0;
    font-size: 12px;
    margin-top: 7px;
    // color: var(--color-aux15);
    // display: none;

    @include respond(tab-land) {
      font-size: 0.8rem;
      margin-top: 0;
      display: block;
    }

    @media (max-width: 1000px) and (min-width: 601px) {
      display: none;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      margin-top: 1px;
    }

    @media (max-width: 327px) {
      display: none;
    }
  }
}

.topNavbar {
  margin-top: 5px;
  width: 25%;
}

.iconContainer {
  @media (max-width: 600px) {
    width: 22%;
  }
}
.mainIcon {
  position: relative;
}

.indicator {
  align-self: center;
  position: absolute;
  margin-top: -33px;
  left: 15px;

  @include respond(tab-land) {
    margin-left: 57px;
    top: 10px;
    left: -43px;
  }

  @media (min-width: 900px) {
    margin-top: -15px;
    left: -43px;
  }

  @media (max-width: 600px) {
    margin-left: 40px;
    left: -23px;
  }
}

.text {
  width: 30px;
}
