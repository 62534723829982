@import '../../../shared/styles/variables.scss';
@import '../../../shared/styles/mixins.scss';

.mobile__create {
  display: block;

  @include respond(tab-land) {
    display: none;
  }
}

.create__image {
  margin-top: 2px;
}

.mobile__hide {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}

.desktop__create {
  display: none;
  margin-left: 10px;

  @include respond(tab-land) {
    display: block;
  }
}
.mobile__create {
  display: block;

  @include respond(tab-land) {
    display: none;
  }
}

.navBar {
  @media (max-width: 600px) {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 3rem;
  width: 100%;
  background-color: $color-black80;
  padding: 0 20px;
  @include spaceBetweenFlex;
  display: flex;
  align-items: center;
  @include respond(tab-port) {
    height: 3.4rem;
  }

  &__wrapper {
    max-width: $main-viewport-width;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.logo {
  height: 1.5rem;
  cursor: pointer;
  margin-right: 15px;
  display: none;

  @include respond(tab-land) {
    display: block;
  }

  img {
    height: 100%;
  }
}

.navBar__items {
  @include spaceBetweenFlex;
  flex-direction: row;
  width: 100%;

  @include respond(tab-port) {
    flex-grow: 1;
    flex-direction: row;
    width: auto;
  }
}

.pagesShortcut {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-around;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.07);

  @media (min-width: 601px) {
    padding: 0;
    position: relative;
    height: 100%;
    box-shadow: none;
    width: auto;
  }

  .create {
    @include centerFlexContent;
    height: 57px;
    width: 57px;
    box-shadow: 5px 5px 40px rgba(0, 51, 153, 0.2);
    border-radius: 100px;

    @include respond(tab-land) {
      box-shadow: none;
      height: 2.5rem;
      width: auto;
      display: block;
      transform: translateY(0);
    }

    &__text {
      display: none;
      color: var(--color-aux8);
      @include respond(tab-land) {
        display: block;
      }
    }
    &__image {
      svg {
        path {
          fill: var(--color-aux8);
        }
      }
      @media (max-width: 600px) {
        svg {
          margin-top: 0.15rem;
          width: 1.2rem;
          height: 5rem;
        }
      }
    }
  }
}

span.create {
  position: relative;
  border-style: solid;
  border-color: transparent;
  border-radius: 20px;
  display: none;
  flex-shrink: 0;

  @include respond(tab-port) {
    height: 2rem;
    margin-right: 15px;
    display: block;
  }

  > button {
    @include centerFlexContent;
    padding: 16px 18px;
    font-weight: 700;
    margin-left: 6px;
    height: 100%;
    width: 100%;
    font-size: 0.95rem;

    svg {
      height: 0.8rem;
      transition: 0.2s;
      margin-right: 0;
      @include respond(tab-land) {
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }

  .anchor {
    clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
    position: absolute;
    bottom: -12px;
    right: 60px;
    height: 20px;
    width: 22px;
    background-color: $color-black80;

    @include respond(tab-land) {
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      top: -8px;
      right: 10px;
    }
  }

  .dropDown {
    top: auto;
    right: 50%;
    bottom: 4.7rem;
    transform: translateX(55%);
    padding: 15px;
    padding-bottom: 0;
    z-index: 100;

    @include respond(tab-port) {
      border-top-right-radius: 0;
      right: 0;
      bottom: auto;
      top: 160%;
      transform: none;
    }

    header {
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid rgba($color-black50, 0.5);
      display: none;

      @include respond(tab-port) {
        display: block;
      }

      p {
        color: $color-black5;
        font-weight: 700;
        margin-bottom: 0;
        font-size: 1rem;
      }
    }

    a,
    button {
      width: 7.1rem;
      margin-bottom: 13px;

      span {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 12px;

        svg {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}

span.userIcon {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 99999px;
  flex-shrink: 0;

  @include respond(tab-port) {
    width: 2rem;
    height: 2rem;
  }

  // svg {
  //   path {
  //     fill: $color-secondary;
  //   }
  // }

  > button {
    svg {
      path {
        fill: $color-secondary;
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
  }

  .dropdownArrow {
    position: relative;
    bottom: 10px;
    left: 25px;
    border: 1px solid $color-secondary;
    border-radius: 4px;
    height: 13px;
    background-color: var(--main-bg-color);
    width: 14px;
    padding: 3px;
  }

  .dropDownProfile {
    left: 0;
    border-top-left-radius: 0;
    top: 160%;

    @media (max-width: 600px) {
      left: -75px;
    }

    @include respond(tab-port) {
      right: 0;
      left: auto;
      border-top-right-radius: 0;
      border-top-left-radius: 12px;
    }

    hr {
      margin: 15px 0;
      border: 1px solid $color-black75;
    }
    a {
      padding-left: 15px;
      margin: 12px 0;
      cursor: pointer;
      flex-wrap: wrap;
      @media (max-width: 600px) {
        justify-content: center;
      }

      > span {
        margin-right: 8px;
        margin-bottom: 5px;
      }

      &:last-of-type {
        p {
          color: $color-red;
          margin-right: 5px;
        }
      }
    }
  }
}

.dropDownProfile {
  position: absolute;
  background-color: $color-black80;
  border-radius: 12px;
  display: none;
  opacity: 0;
  box-shadow: $box-shadow1;
  min-width: 160px;
  @media (max-width: 600px) {
    min-width: 120px;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  a,
  button {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p {
      color: $color-black5;
      font-size: 0.9rem;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}

.dropDown {
  display: none;
  opacity: 0;
  background-color: $color-black80;
  border-radius: $border-radius5;
  box-shadow: $box-shadow3;
  padding: 25px;
  width: 100%;
  height: 100%;

  header {
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba($color-black50, 0.5);
    display: none;

    @include respond(tab-port) {
      right: 0;
      left: auto;
      border-top-right-radius: 0;
      border-top-left-radius: 12px;
    }

    hr {
      margin: 15px 0;
    }
    a {
      width: 7.1rem;
      padding-left: 15px;
      margin: 12px 0;
      cursor: pointer;

      &:last-of-type {
        p {
          color: $color-red;
        }
      }
    }
  }
}

.dropDown {
  position: absolute;
  background-color: $color-black80;
  border-radius: 12px;
  display: none;
  opacity: 0;
  box-shadow: $box-shadow1;

  &.show {
    display: block;
    opacity: 1;
  }

  a,
  button {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p {
      color: $color-black5;
      font-size: 0.9rem;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}

.input {
  height: 1.8rem;
  flex: 1;
  background-color: $color-black100;
  border-radius: 30px;
  margin-left: 15px;
  position: relative;
  display: block;
  font-family: inherit;

  @include respond(tab-port) {
    margin-right: auto;
    max-width: 300px;
    height: 2rem;
    flex-shrink: 0;
  }
  @media (max-width: 600px) {
    margin-left: 0;
  }

  button.searcIcon {
    @include centerFlexContent;
    color: $color-black25;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2.5rem;

    svg {
      height: 0.95rem;
      cursor: pointer;
      path {
        fill: var(--color-aux101);
      }
    }
  }

  button.clearIcon {
    @include centerFlexContent;
    color: $color-black25;
    position: absolute;
    top: 4.3px;
    right: 5px;
    width: 1.4rem;
    height: 1.4rem;
    background-color: $color-black80;
    border-radius: 99999px;
    box-shadow: $box-shadow3;

    &:active {
      transform: scale(0.95);
    }

    svg {
      height: 0.55rem;
      cursor: pointer;
    }
  }

  input {
    height: 100%;
    color: $color-black25;
    border: none;
    background-color: var(--color-aux14);
    padding-left: 2.5rem;
    padding-right: 2rem;
    width: 100%;
    border-radius: 8px;
    font-family: inherit;
    appearance: none;

    &::-webkit-search-cancel-button {
      display: none;
    }

    &::placeholder {
      color: var(--color-aux6);
      @media (max-width: 600px) {
        font-size: $font-size-s;
      }
    }

    &:focus {
      outline: none;
      border: 1px solid $color-black25;
    }
  }
}

.navModal {
  display: none;
  opacity: 0;
  background-color: $color-black80;
  border-radius: $border-radius5;
  box-shadow: $box-shadow3;
  padding: pxToRem(30);
  width: 450px;
  height: 100%;
  @include customScrollbar;

  header {
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-aux106);
    display: none;

    @include respond(tab-port) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > button {
      color: $color-black5;
    }

    > p {
      color: $color-black5;
      font-weight: 700;
      margin-bottom: 0;
      line-height: pxToRem(47);
      font-size: pxToRem(25);
    }
  }

  hr {
    margin: 15px 0;
  }

  a {
    width: 100%;
    padding-left: 15px;
    margin: 12px 0;
    cursor: pointer;

    &:last-of-type {
      p {
        color: $color-red;
      }
    }
  }

  &.show {
    display: block;
    opacity: 1;
    // max-height: calc(100vh - 210px);
    overflow-x: hidden;
  }

  .section {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: pxToRem(25);
    }

    &__icon {
      margin-right: 20px;
    }

    &__label {
      font-size: pxToRem(20);
      font-weight: 700;
      line-height: pxToRem(30);
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      > div {
        display: flex;
        margin-left: pxToRem(10);
      }
    }

    &__desc {
      font-size: pxToRem(14);
      font-weight: 600;
      color: var(--color-aux53);
      line-height: pxToRem(21);
    }
  }
}

:global(.light) {
  .navModal {
    .section {
      color: #18243a;
    }
  }
}

:global(.dark) {
  .navModal {
    .section {
      color: #e6e6e6;
    }
  }
}

.globalSearch {
  position: absolute;
  background-color: $color-black80;
  border-radius: 12px;
  box-shadow: $box-shadow1;
  display: block;
  opacity: 1;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    p {
      color: $color-black5;
      font-size: 0.9rem;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  @media (max-width: 580px) {
    position: fixed;
    bottom: 4rem;
    left: 0;
    border-radius: 0;
    right: 0;
    top: 3rem;
    width: 100%;
    margin: auto;
  }
}

.mobile__show {
  display: flex;
  width: 35%;
  justify-content: space-evenly;
  @include respond(tab-port) {
    display: none;
  }
}

.createBtnLM {
  background-color: var(--color-primary);
  border-radius: 100px;
}

.createBtnDM {
  @include makeGradBorderAnimated(var(--color-aux12), 2px, 100px);
}

.activeCreateBtn {
  svg {
    transform: rotate(-45deg);
  }
}

.loaderSupport {
  margin-left: 15px;
}
