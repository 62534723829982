@import "../../../../shared/styles/mixins.scss";
@import "../../../../shared/styles/variables.scss";

.AvatarWrapper {
    margin: auto;
    position: relative;
}

.Avatar {
    display: block;
    margin: auto;
    height: 100%;
    width: 100%;
    border-radius: 999999px;
    object-fit: cover;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);
    color: var(--color-black80);
    font: inherit;
    line-height: 100%;
    font-weight: 700;
    background-color: var(--color-black5);
    &_noBg {
        @extend .Avatar;
        background-color: initial;
    }
}

.badgeWrapper {
    > div {
        margin: 0 auto 3px;
    }
}

.Initials {
    display: flex;
    align-items: center;
    justify-content: center;
}
