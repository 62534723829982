@import '../../../../../../shared/styles/variables.scss';

.indicator {
  display: block;
  width: 7px;
  height: 7px;
  background-color: $color-red;
  border-radius: 1000000px;
  margin-left: 5px;
}
