@import '../../../../src/shared/styles/variables.scss';
@import '../../../../src/shared/styles/mixins.scss';

label.checkbox {
  position: relative;
  width: 2.87rem;
  height: 1.66rem;
  display: block;

  input[type='checkbox'] {
    position: absolute;
    width: 100%;
    height: 100%;
    appearance: none;
    z-index: 100;
    opacity: 0;
    cursor: pointer;
  }

  span {
    border-radius: 100px;
    background-color: var(--color-aux52);
    border: 1px solid var(--color-black75);
    width: 100%;
    height: 100%;
    display: block;
    transition: 0.1s;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 4px;
      left: 4px;
      width: 1.063rem;
      height: 1.063rem;
      background-color: var(--color-aux51);
      border-radius: 99999px;
      transition: 0.1s;
    }
  }

  input[type='checkbox']:checked + span {
    background: var(--color-aux77);

    &::after {
      background-color: var(--color-aux3);
      content: '';
      transform: translateX(21px);
    }
  }
}
