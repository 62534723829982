@import '../../../../shared/styles/variables.scss';
@import '../../../../shared/styles/mixins.scss';

.navBar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 51px;
  width: 100%;
  background-color: $color-black80;
  padding: 0 16px;

  &__wrapper {
    max-width: $main-viewport-width;
    padding: 0 1.2rem;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.navBar__items {
  @include spaceBetweenFlex;
  flex-direction: row;
  width: 100%;
  height: 36px;
}
span.userIcon {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex-shrink: 0;

  > button {
    div,
    img {
      min-height: 36px;
      min-width: 36px;
    }
    svg {
      path {
        fill: $color-secondary;
      }
    }
  }
  .dropDownProfile {
    left: 0;
    border-top-left-radius: 0;
    top: 130%;

    @media (max-width: 600px) {
      left: -75px;
    }
    hr {
      margin: 15px 0;
      border: 1px solid $color-black75;
    }
    a {
      padding-left: 15px;
      margin: 12px 0;
      cursor: pointer;
      flex-wrap: wrap;
      @media (max-width: 600px) {
        justify-content: center;
      }

      > span {
        margin-right: 8px;
        margin-bottom: 5px;
      }

      &:last-of-type {
        p {
          color: $color-red;
          margin-right: 5px;
        }
      }
    }
  }
}

// profile dropdown styles
.dropDownProfile {
  position: absolute;
  background-color: $color-black80;
  border-radius: 12px;
  display: none;
  opacity: 0;
  box-shadow: $box-shadow1;
  min-width: 160px;
  @media (max-width: 600px) {
    min-width: 120px;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  a,
  button {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p {
      color: $color-black5;
      font-size: 0.9rem;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}

//Global search input styles
.globalSearchInput {
  display: block;
  position: relative;
  background-color: $color-black100;
  border-radius: 8px;
  height: 2rem;
  max-width: 100%;
  button.searchIcon {
    @include centerFlexContent;
    color: $color-black25;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2.5rem;

    svg {
      height: 0.95rem;
      cursor: pointer;
      path {
        fill: var(--color-aux11);
      }
    }
  }

  button.clearIcon {
    @include centerFlexContent;
    color: $color-black25;
    position: absolute;
    top: 4.3px;
    right: 5px;
    width: 1.4rem;
    height: 1.4rem;
    background-color: $color-black80;
    border-radius: 99999px;
    box-shadow: $box-shadow3;

    &:active {
      transform: scale(0.95);
    }

    svg {
      height: 0.55rem;
      cursor: pointer;
    }
  }

  input {
    height: 100%;
    color: $color-black25;
    border: none;
    background-color: var(--color-aux14);
    padding-left: 2.5rem;
    padding-right: 2rem;
    width: 100%;
    border-radius: 8px;
    font-family: inherit;
    appearance: none;

    &::-webkit-search-cancel-button {
      display: none;
    }

    &::placeholder {
      color: var(--color-aux6);
      @media (max-width: 600px) {
        font-size: $font-size-s;
      }
    }

    &:focus {
      outline: none;
      border: 1px solid $color-black25;
    }
  }
}

.globalSearch {
  position: absolute;
  background-color: $color-black80;
  border-radius: 12px;
  box-shadow: $box-shadow1;
  display: block;
  opacity: 1;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    p {
      color: $color-black5;
      font-size: 0.9rem;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  @media (max-width: 580px) {
    position: fixed;
    bottom: 4rem;
    left: 0;
    border-radius: 0;
    right: 0;
    top: 3.7rem;
    width: 100%;
    margin: auto;
  }
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-left: 18px;
  svg {
    height: 30px;
    width: 30px;
  }
}
