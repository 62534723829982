@import "../../../../shared/styles/variables.scss";
@import "../../../../shared/styles/mixins.scss";

.tabsAudience{
    padding: 0;
    height: 100%;
    width: 100%;

    @media (max-width: 37.5em) {
        // padding-top: 0.5rem;
        padding-top: 3rem;
    }

    &__Btn {
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }
}

.indicatorStyle {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -15px;
    left: 38px;
    >p {
        font-size: 9px;
    }
}

.tabs {
    @include createCard;
    width: 100%;
    max-width: 550px;

    .tabSmall {
        font-size: 13px;
        padding: 0 0 0;
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
        gap: 7px;
    }

    &__callRoom {
        display: grid;
        grid-template-rows: 3rem 1fr;
    }

    &__BtnWrapperAudience{
        z-index: 100;
        background-color: $color-black80;
        display: flex;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        box-shadow: 24px -5px 30px 5px rgb(70 70 70 / 10%);
        // padding: 0 0 15px 0;
        // margin-bottom: 8px;
        justify-content: space-around;
        position: relative;

        @media (max-width: 37.5em) {
            position: fixed;
            width: 100%;
            height: 3rem;
            top: 0;
            left: 0;
            z-index: 3;
        }

        .notification {
            margin-left: 5px;
        }
    }

    &__BtnWrapper {
        // background-color: $color-black5;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        border-bottom: 1px solid var(--color-aux24);
        padding: 15px 5px;
        margin-bottom: 15px;
    }

    &__Btn {
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 160%;
        color: $color-black50;
        position: relative;
        outline-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            width: 0;
            transition: all .3s ease-out;
            margin: auto;
            left: 0;
            right: 0;
            content: "";
            display: block;
            position: absolute;
            bottom: -10px;
            background: var(--color-aux68);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 4px;
        }
    }

    &__btnActive.tabs__Btn {
        .tabs_Title {
            color: var(--color-aux10);
        }

        &::after {
            width: 50%;
        }
    }
    &__btnActiveAudience.tabs__Btn {
        .tabs_Title {
            color: $color-black5;
        }

        &::after {
            width: 50%;
        }
    }
}
