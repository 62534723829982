@import "../../../../shared/styles/variables.scss";
@import "../../../../shared/styles/mixins.scss";

.ProModal {
  max-height: 90vh;
  overflow: auto;
  border-radius: $border-radius5;
  background-color: $color-black80;
  width: 100%;
  margin: auto;
  max-width: 500px;

  @include customScrollbar;

  .accountDetails {
    display: none;
  }
  @media (max-width: 600px) {
    max-height: 80vh;
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin: -30px 0 20px;
}

.bgGrey {
  background-color: initial;
}

.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > button {
    z-index: 1;

    > svg {
      path {
        fill: var(--color-secondary);
      }
    }
  }
}

.cards {
  display: flex;

  .pricing {
    // margin-top: pxToRem(15);

    &__amount {
      color: var(--color-secondary);
      font-weight: 700;
      font-size: pxToRem(36) !important;
      margin-top: 15px;
    }

    &__duration {
      color: var(--color-secondary);
      font-size: pxToRem(20);
      margin-left: 10px;
      font-weight: 600;

      // @media (max-width: 600px) {
      //   font-size: 12px;
      // }
    }
  }

  .card {
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 100%;

    &__header {
      display: flex;
      justify-content: space-around;
      background: var(--color-aux23);
      border: 1px solid var(--color-aux40);
      border-radius: pxToRem(10);
      padding: 15px 10px;
      margin-bottom: pxToRem(30);

      @media (max-width: 601px) {
        flex-wrap: wrap;
      }
    }

    &__headerLanding {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--color-secondary);
      padding: pxToRem(20);
      align-items: center;
      border-radius: 10px;
      background-color: #181818;
      margin-bottom: pxToRem(35);

      > p {
        font-size: pxToRem(24);
        line-height: 36px;
        font-weight: 700;
      }

      > div {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: center;
        background: #101010;
        border: 1px solid #808080;
        border-radius: pxToRem(10);
        padding: 15px 10px;
        margin-top: pxToRem(20);
        margin-bottom: pxToRem(35);
      }
    }

    &__footer {
      text-align: center;
      width: 100%;

      p {
        margin-top: pxToRem(15);
        line-height: 1.5;
        letter-spacing: normal;
        color: var(--color-aux40);
        font-size: pxToRem(10);
        font-weight: 700;
        margin-bottom: pxToRem(0);
      }

      a {
        color: $color-secondary;
        margin-top: 0;
        padding: 0;
      }

      span {
        line-height: 15px;
        color: #808080;
        font-size: pxToRem(10);
        font-weight: 500;
      }

      div {
        display: flex;
        flex-direction: column;
        text-align: left;
      }

      img {
        margin-top: 15px;
      }
    }

    &__title {
      font-size: pxToRem(20);
      font-weight: 700;
      align-self: center;
      color: var(--color-aux78);

      @media (max-width: 600px) {
        text-align: center;
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }
    }

    &__text {
      margin-bottom: pxToRem(25);
      text-align: left;

      p {
        font-size: pxToRem(18);
        font-weight: 700;
        line-height: pxToRem(27);
        margin-left: pxToRem(15);

        @media (max-width: 601px) {
          font-size: pxToRem(16);
        }
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      padding: 3px 20px;
      color: var(--color-aux150);
      margin-bottom: 10px;

      > div {
        display: flex;
        justify-content: center;
      }

      @media (max-width: 600px) {
        padding: 5px;
      }
    }

    &__proTitle {
      color: var(--color-aux150);
      font-size: pxToRem(20);
      text-align: center;
      margin-bottom: 15px;
    }

    &__checkmarks {
      background-color: var(--color-aux78);
      height: 18px;
      width: 18px;
      border-radius: 999999px;

      svg {
        align-self: center;

        path {
          fill: var(--color-aux23);
        }
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 300px;

  p {
    color: var(--color-aux150) !important;
    font-size: 32px !important;
    margin-top: 25px;
    font-weight: 700;
    letter-spacing: -0.02rem;
    line-height: 42px;
    text-align: center;
  }
}

.ProModal {
  padding: 20px;

  .accountDetails {
    display: block;
    font-size: 11.0984px;
    margin-bottom: 1rem;

    h3 {
      line-height: 190%;
      display: flex;
      align-items: center;
      color: $color-black50;
      opacity: 0.7;
    }

    p {
      font-weight: 500;
      line-height: 190%;
      letter-spacing: 0.002em;
      color: $color-black25;
      margin: 3px 0;
    }

    .email {
      text-decoration-line: underline;
    }
  }

  .card__footer br {
    display: none;
  }
}
