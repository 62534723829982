@import "../../../../shared/styles/variables.scss";
@import "../../../../shared/styles/mixins.scss";

.searchInput {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--color-aux110);
  border-radius: $border-radius3;

  button {
    @include centerFlexContent;
    color: $color-black25;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1.8rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  input {
    height: 100%;
    width: 100%;
    font: inherit;
    font-weight: 700;
    font-size: $font-size-s;
    color: var(--color-aux150);
    background: var(--color-aux151);
    border: 1px solid var(--color-aux106);
    padding-left: 2.5rem;
    padding-right: 1rem;
    border-radius: $border-radius3 !important;
    @include overflowEllipses();

    &::placeholder {
      color: var(--color-black50);
      font-weight: 600;
      font-size: 13px;
    }

    &:focus {
      outline: none;
      outline-width: 0;
    }
  }
}
