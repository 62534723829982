@import "../../../shared/styles/mixins.scss";
@import "../../../shared/styles/variables.scss";

.industryModal {
  padding: 20px;
  border-radius: $border-radius5;
  background-color: $color-black80;
  z-index: 100000;
  box-shadow: $box-shadow3;
  max-height: 85%;
  overflow-y: auto;
  width: 100%;
  overflow-y: overlay;
  scrollbar-width: none;
  width: 100%;
  @include customScrollbar;

  header {
    @include spaceBetweenFlex;

    p {
      font-size: $font-size-l;
      font-weight: 700;
      color: $color-black5;
    }

    button {
      align-self: flex-start;
      svg {
        height: 15px;
        width: 15px;

        path {
          color: $color-secondary;
        }
      }
    }
  }

  p.ques {
    color: var(--color-aux11);
    font-size: $font-size-s;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .search {
    width: 100%;
    height: 3rem;
    border-radius: $border-radius3;
    background-color: var(--color-aux110);
    margin-top: 10px;

    svg {
      height: 0.95rem;
      margin-right: 5px;
      cursor: pointer;
      path {
        fill: --color-black75;
      }
    }
  }

  .optionContainer {
    display: flex;
    overflow: scroll;
    max-height: 110px;
    scrollbar-width: none;
    flex-direction: column;
    @include customScrollbar;
  }

  .options {
    margin: 15px 0;
    overflow-y: auto;
    overflow-y: overlay;
    scrollbar-width: none;
    @include customScrollbar;
    max-height: 13rem;

    @media (max-width: 600px) {
      max-height: 8rem;
    }

    @media (min-height: 720px) and (min-width: 600px) {
      max-height: 18rem;
    }

    .option {
      @include spaceBetweenFlex;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      p {
        color: $color-black50;
        font-size: $font-size-s;
        font-weight: 600;
        transition: 0.1s;

        &.active {
          color: var(--color-secondary);
        }
      }

      span {
        width: 1.3rem;
        height: 1.3rem;
        @include centerFlexContent;
        border-radius: 99999px;
        border: 2px solid var(--color-aux11);
        transition: 0.1s;

        &.active {
          background-color: var(--color-secondary);
          border: 2px solid var(--color-secondary);
          svg {
            path {
              fill: var(--color-aux23);
            }
          }
        }
      }
    }
  }

  .submitBtn {
    padding: 10px 40px;
    background-color: var(--color-aux69);
    color: var(--color-aux3);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
  }

  .selected {
    border-bottom: 1px solid var(--color-aux116);
    padding-bottom: 15px;

    > p {
      color: $color-black50;
      padding-bottom: 0.5rem;
      font-size: $font-size-s;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 5px;
      // border-bottom: 1px solid --color-black50;
    }

    @media (max-width: 600px) {
      max-height: calc(100vh - 540px);
      overflow: scroll;
    }
  }
}

.loader {
  // margin-top: 10px;
  height: 2rem;
  width: 100%;
  margin: 10px auto 0;
  @include centerFlexContent;
}
