@import "../../../shared/styles/variables.scss";
@import "../../../shared/styles/mixins.scss";

.badge {
  box-shadow: -2.724px 5.346px 18px rgba(115, 115, 115, 0.102);
  font-family: "Poppins", sans-serif;
  background: var(--color-aux68);
  color: var(--color-aux23);
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  max-width: max-content;
  padding: 0px 6px;
}

.floatBadge {
  transform: translate(0, 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tiny {
  font-size: 5px;
  line-height: 10px;
}

.small {
  font-size: 9px;
  line-height: 14px;
}

.medium {
  font-size: 7px;
}

.big {
  font-size: 11px;
  line-height: 18px;
}
