@import '../../../../shared/styles/variables.scss';
@import '../../../../shared/styles/mixins.scss';

.wrapper {
  width: 450px;

  @media (max-width: 580px) {
    width: 100%;
    height: 100%;
  }

  .section .section {
    max-height: 100%;
    overflow: hidden;
  }

  .section {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    color: $color-black25;
    @include customScrollbarX;

    @media (max-width: 580px) {
      max-height: calc(100vh - 15rem);
    }
    @media (max-width: 434px) {
      max-height: calc(100vh - 18rem);
    }
  }
}

.GlobalSearch {
  a {
    color: var(--color-aux73);
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    color: $color-black5;

    h4 {
      font-weight: 700;
      font-size: $font-size-l;
    }
    .btnLink {
      font-size: $font-size-m;
      text-decoration: underline;
      color: $color-black25;
    }
  }
}

.PeopleSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &__userDetails {
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 100%;
  }

  &__names {
    display: flex;
    flex-direction: column;
    width: 84%;
  }

  .fullName {
    font-weight: 700;
    text-transform: capitalize;
    font-size: $font-size-m;
    margin-bottom: 2px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-black25;
  }

  .title {
    font-weight: 600;
    margin-right: 3px;
    font-size: 10px;
    color: $color-black50;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .btn {
    border: 0.99998px solid $color-primary;
    padding: 0.3rem 0.5rem;
    box-sizing: border-box;
    border-radius: 5.99988px;
    font-weight: 600;
    font-size: $font-size-s;
    line-height: 190%;
    color: $color-primary;
    transition: all 0.3s ease;

    &:hover {
      color: $color-black5;
      background-color: $color-primary;
    }
  }
}

.ContentSearch {
  margin-bottom: 1rem;
  .moreBtn {
    color: $color-primary;
    display: contents;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  .user {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .userDetails {
    .fullName {
      font-size: $font-size-m;
      font-weight: 700;
      text-transform: capitalize;
      line-height: 160%;
      color: $color-black5;
    }

    .time {
      font-weight: 500;
      font-size: $font-size-s;
      color: var(--color-black25);
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-top: 10px;

    a {
      display: inline;
    }
  }

  .text {
    flex: 2;
    margin-right: 10px;
    font-weight: 600;
    font-size: $font-size-s;
    line-height: 150%;
    color: var(--color-black5);

    overflow: hidden;
    display: -webkit-box;
    line-clamp: 4;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  a {
    display: inline;
  }

  .image {
    flex: 1;
    height: 80px;
    width: 100%;
    aspect-ratio: 2 / 1;

    img {
      border-radius: 10px;
      object-fit: fill;
      image-rendering: pixelated;
    }
  }
}

.EventSearch {
  margin-bottom: 1rem;

  .content {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    cursor: pointer;
  }

  .text {
    flex: 2;
    margin-right: 10px;
    font-weight: 600;
    font-size: $font-size-s;
    line-height: 150%;
    color: $color-black75;
  }

  .eventDate {
    display: flex;
    align-items: center;
    color: var(--color-aux73);
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-s;
  }

  .eventTitle {
    font-weight: 700;
    font-size: $font-size-m;
    color: $color-black25;
  }

  .image {
    flex: 1;
    width: 100%;
    height: 80px;
    // padding: 2px;
    img {
      border-radius: 10px;
      object-fit: fill;
      image-rendering: pixelated;
    }
  }
}

.JobSearch {
  margin-bottom: 1.3rem;
  cursor: pointer;

  .jobTitle {
    font-size: $font-size-m;
    color: $color-black25;
    text-transform: capitalize;
    margin-bottom: 4px;
  }

  .jobDetails {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .jobCompany {
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    color: var(--color-aux73);
    white-space: nowrap;
  }

  .jobSalary {
    color: $color-black50;
    font-weight: 600;
    font-size: $font-size-s;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .jobPay {
    font-size: $font-size-s;
    color: var(--color-aux74);
    margin-left: 3px;
  }
}

.DealSearch {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.3rem;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 15px;

  .title {
    margin-bottom: 4px;
    font-size: $font-size-m;
    color: $color-black25;
    font-weight: 700;
  }
  .content {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    font-size: $font-size-s;
    color: $color-black50;
    padding-right: 10px;
    font-weight: 500;

    .dealImage {
      margin-right: 10px;
      border-radius: 12px;
      box-shadow: 0 0 2.4936px rgba(70, 70, 70, 0.1);
      padding: 4px;
    }

    img {
      border-radius: 12px;
      object-fit: contain;
      width: 45px;
      height: 45px;
    }
  }

  button {
    border: 0.99998px solid var(--color-aux73);
    box-sizing: border-box;
    color: var(--color-aux1);
    border-radius: 5.99988px;
    font-weight: 600;
    font-size: 11.9998px;
    background-color: var(--color-aux73);
    padding: 10px 1rem;
    transition: all 0.2s;

    &:hover {
      color: var(--color-aux11);
      background-color: var(--aux4);
    }
  }
}

.dealDetailModal {
  border-radius: $border-radius4;
  background-color: $color-black80;
  padding: 20px;

  h3 {
    color: $color-black5;
    font-size: $font-size-l;
  }

  .header {
    @include spaceBetweenFlex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-black25;

    button {
      width: 1rem;
      height: 1rem;

      svg {
        path {
          fill: $color-black50;
        }
      }
    }
  }

  .brand {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 25rem;

    img {
      width: 4rem;
      height: 4rem;
      border-radius: $border-radius4;
      margin-right: 10px;
      object-fit: contain;
    }
  }

  span,
  p {
    color: $color-black50;
    font-weight: 600;
    font-size: $font-size-s;
    letter-spacing: 0.02rem;
  }

  span {
    display: block;
  }

  .requirement {
    margin-top: 15px;

    h3 {
      margin-bottom: 5px;
    }
    p {
      margin-top: 10px;
    }
  }

  .btnWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .submitBtn {
      padding: 15px 30px;
      font-weight: 600;
      font-size: $font-size-s;
      color: var(--color-aux20);
      background-color: $color-primary;
      border-radius: $border-radius2;
    }
  }
}
