@import '../../../shared/styles/mixins.scss';
@import '../../../shared/styles/variables.scss';

.locationModal {
  padding: 20px;
  border-radius: $border-radius5;
  background-color: $color-black80;
  max-height: 85%;
  width: 100%;
  text-align: left;

  @include customScrollbar;

  header {
    @include spaceBetweenFlex;

    p {
      font-size: $font-size-l;
      font-weight: 700;
      color: $color-black5;
    }

    button {
      svg {
        height: 9px;

        path {
          color: $color-black50;
        }
      }
    }
  }

  p.ques {
    color: $color-black25;
    font-size: $font-size-s;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .search {
    width: 100%;
    height: 3rem;
    border-radius: $border-radius3;
    background-color: $color-black5;
  }

  .options {
    margin: 15px 0;

      max-height: 18rem;
    @include customScrollbar;

    .option {
      width: 100%;
      justify-content: flex-start;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      p {
        color: $color-black50;
        font-size: $font-size-s;
        font-weight: 600;
        text-align: left;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .submitBtn {
    padding: 10px 40px;
    background-color:$color-primary;
    color: var(--color-aux3);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    right: -100%;
    transform: translateX(-100%);
  }
}
