@import '../../../shared/styles/variables.scss';
@import '../../../shared/styles/mixins.scss';

.chat {
    padding: 10px;
    padding-left: 25px;
    height: 5.1rem;
    width: 100%;
    cursor: pointer;
    transition: .2s;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns:  3.75rem 1fr;
    grid-gap: 10px;
  
    &.active {
      background-color: var(--color-aux30);
  
      .activeSpan {
        transform: translateX((0));
      }
    }
  
    .activeSpan {
      height: 100%;
      width: 0.4rem;
      background: $color-primary;
      background: linear-gradient(to top, $color-secondary, $color-primary);
      position: absolute;
      border-radius: 0 5px 5px 0;
      left: 0;
      top: 0;
      transform: translateX(-100%);;
      transition: .2s;
    }
  
    &_userIcon {
      position: relative;
      align-self: center;
      width: 3.75rem;
      height: 3.75rem;
    }
  
    &_details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      // padding: 7px 0;
      padding: 4px 0;
  
      .top {
        @include spaceBetweenFlex;
  
        .name {
          font-size: $font-size-l;
          color: $color-black25;
          font-weight: 700;
          // max-width: 14rem;
          @include overflowEllipses(8.5rem);
  
          @include respond(tab-land) {
            @include overflowEllipses(12.5rem);
          }
        }
  
        .time {
          color: var(--color-aux106);
          font-size: $font-size-s;
          font-weight: 500;
          margin-left: 15px;
          white-space: nowrap;
        }
      }
  
      .lastMessage {
        color: $color-black50;
        font-size: $font-size-m;
        font-weight: 500;
        @include overflowEllipses(11rem);
  
        @include respond(tab-land) {
          @include overflowEllipses(18rem);
        }
      }
  
      .bottom {
        @include spaceBetweenFlex;
  
        > div {
          display: flex;
          align-items: center;
  
          .deleteBtn {
            height: 1rem;
            margin-left: 10px;
  
            svg {
              height: 100%;
            }
          }
  
          .unreadCounter {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-s;
            font-weight: 700;
            color: var(--color-aux3);
            height: 22px;
            width: 22px;
            background-color: $color-red;
            border: 0;
            border-radius: 99999px;
          }
        }
      }
    }
  
    &.quickChatView {
      height: 4.3rem;
      grid-template-columns:  3rem 1fr;
      padding-left: 0;
      padding-right: 0;
  
      .chat_userIcon {
        height: 3rem;
        width: 3rem;
      }
  
      .chat_details {
        .top {
          .name {
            font-size: $font-size-m;
            max-width: 8.5rem;

          }
        }
  
        .bottom {
          .lastMessage {
            font-size: $font-size-s;
            max-width: 11rem;
          }
        }
      }
    }
  }
  