@import "./../../shared/styles/variables.scss";
@import "./../../shared/styles/mixins.scss";

.stripeForm {
  background-color: $color-black80;
  width: 100%;
  //   padding: 2rem;

  &__header {
    @include spaceBetweenFlex;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-black25;
    h2 {
      font-size: 16px;
      line-height: 190%;
      display: flex;
      align-items: center;
      color: $color-black5;
    }
  }

  .stripeInput {
    //   margin-bottom: 1rem;

    &__label {
      font-weight: 700;
      font-size: pxToRem(13);
      line-height: 20px;
      text-align: left;
      color: var(--color-aux11);
    }

    .EntreInput {
      padding: 14px 15px 13px;
      font-family: $font-family;
      border: 1px solid var(--color-aux40);
      // color: var(--color-aux154);
      border-radius: 8px;
      outline-width: 0;
      height: 3rem;
      background-color: var(--color-aux5);
      transition: all 0.3s linear;

      &:focus {
        border: 1px solid var(--color-black50);
      }

      margin-top: 10px;
      width: 100%;
      color: $color-black75;
      font-weight: 600;
      resize: vertical;
      font-size: 12px;
      // height: 57px;
      // resize-: none;

      input {
        font-family: $font-family;
      }

      &::placeholder {
        color: $color-black25;
      }
    }
  }

  .form {
    &__header {
      margin-bottom: 1rem;
    }
    &__heading {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #333333;
    }

    &__text {
      font-size: 12px;
      color: $color-black50;
    }
  }

  &__footer {
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: pxToRem(15);
    // max-width: 80%;
    align-items: center;

    >button {
      font-size: 16px !important;
      font-weight: 700 !important;
    }
  }
}