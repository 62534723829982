@import '../../../../shared/styles/variables.scss';
@import '../../../../shared/styles/mixins.scss';

.wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  position: fixed;
  z-index: 999;
  bottom: 0;
  height: 66px;
  left: 0;
}
.firstBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  > a {
    width: 100%;
  }
}
.centerButton {
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
  > button {
    border-radius: 100px;
    box-shadow: $box-shadow13;
    height: 56px;
    width: 56px;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.secondBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  > a {
    width: 100%;
  }
}

.navModal {
  display: none;
  opacity: 0;
  background-color: $color-black80;
  border-radius: $border-radius5;
  box-shadow: $box-shadow3;
  padding: 10px 25px;
  width: 95%;
  height: auto;

  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-aux106);

    @include respond(tab-port) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > button {
      color: $color-black5;
    }

    > p {
      color: $color-black5;
      font-weight: 700;
      margin-bottom: 0;
      line-height: pxToRem(47);
      font-size: pxToRem(25);
    }
  }

  hr {
    margin: 15px 0;
  }

  a {
    width: 100%;
    padding-left: 15px;
    margin: 12px 0;
    cursor: pointer;

    &:last-of-type {
      p {
        color: $color-red;
      }
    }
  }

  &.show {
    display: block;
    opacity: 1;
    max-height: 100%;
    overflow-x: hidden;
  }

  .section {
    text-align: left;
    width: 100%;
    margin-bottom: pxToRem(20);
    color: $color-black5;
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 20px;
    }

    &__label {
      font-size: pxToRem(20);
      font-weight: 700;
      line-height: pxToRem(30);
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      > div {
        display: flex;
        margin-left: pxToRem(10);
      }
    }

    &__desc {
      font-size: pxToRem(14);
      font-weight: 600;
      color: var(--color-aux53);
      line-height: pxToRem(21);
    }
  }
}

.activeCreateBtn {
  svg {
    transform: rotate(-45deg);
  }
}

:global(.dark) {
  .wrapper {
    background-color: var(--color-black100);
    border-top: 1px solid #404040;
  }
  .createBtn {
    background: $gradient-primary2;
    box-shadow: 0px 13px 26px rgba(40, 249, 218, 0.15);
    svg {
      path {
        fill: $color-black88;
      }
    }
  }
}

:global(.light) {
  .wrapper {
    background-color: var(--color-white);
    border-top: 1px solid #b9b9b9;
  }
  .createBtn {
    background-color: var(--color-primary);
    svg {
      path {
        fill: $color-black80;
      }
    }
  }
}
