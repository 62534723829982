@import '../../../../../shared/styles/mixins.scss';
.container {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  overflow-x: scroll;
  max-width: 100%;
  @include customScrollbar;

  .filterItem {
    background: rgba(130, 130, 130, 0.1);
    border-radius: 19.1579px;
    font-weight: 500;
    font-size: 13px;
    padding: 0.4rem 0.8rem;
    color: #828282;
    margin-right: 10px;
    display: flex;
    white-space: nowrap;
    align-items: center;

    svg {
      path {
        fill: var(--color-black25);
      }
    }

    &:hover,
    &.active {
      background: rgba(0, 51, 153, 0.1);

      @include svgHoverColorChange;
      .text {
        color: $color-secondary;
      }
    }
  }

  .text {
    margin-right: 6px;
    text-transform: capitalize;
  }
}

.clearBtn {
  font-weight: 500;
  margin-right: 10px;
  font-size: 14px;
  color: $color-primary;
}
